export const getUsernameFromURL = () => {
  const hostname = window.location.hostname;
  let username;

  switch (hostname) {
    case "stuartlogan.com":
      username = "stuart";
      break;
    case "daptrax.com":
      username = "compserdap";
      break;
    case "ikaradesign.eu":
      username = "oanacarianopol5582";
      break;
    case "313mediagroup.com":
      username = "khuber8076";
      break;
    case "ericoh.ca":
      username = "echo416";
      break;
    case "galacticeclipse.com":
      username = "Phenomenone";
      break;
    case "thechaco.com":
      username = "Alejandro1366";
      break;
    case "www.kerryleatham.com":
      username = "KLeatham";
      break;
    case "cloudfoundrymusic.com":
      username = "james4921";
      break;
    default:
      username = hostname.split(".")[0];
  }
  return username;
};

export function beginsWithVowel(string) {
  return /^[aeiouAEIOU]/.test(string);
}
